import { Box } from "@mui/material";
const JobsPage: React.FC = () => {

  return (
    <Box sx={{
      backgroundColor: '#FBF8F5',
      paddingBottom: '75px'
  }}> 
        <iframe
        src="https://site.heardnyc.com/jobs-embedded"
        style={{ width: "100vw", height: "100vh", border: "none" }}
        title="Embedded Jobs Page from HeardNYC"
        ></iframe>
    </Box>
  )
}

export default JobsPage;
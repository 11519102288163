import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';
import './App.css';
// import AboutUsPage from './pages/AboutUsPage';
import AboutUsPage from './pages/AboutUsPageTemp/AboutUsEmbedded';
import ContactUsPage from './pages/ContactUsPage';
import NotFound from './pages/ErrorPage/NotFound';
import EstablishmentPage from './pages/EstablishmentPage';
import ExplorePage from './pages/ExplorePage';
// import HomePage from './pages/HomePage';
import HomePage from './pages/HomePageTemp/HomeEmbedded';
import Layout from './pages/Layout';
import NewReviewPage from './pages/NewReviewPage';
import NewReviewLandingPage from './pages/NewReviewPage/landingPage';
import NewReviewSubmittedPage from './pages/NewReviewPage/submittedPage';
import PrivacyPolicyPage from './pages/PolicyPages/PrivacyPolicyPage';
import TermsOfUsePage from './pages/PolicyPages/TermsOfUsePage';
import ProfilePage from './pages/ProfilePage';
import AuthRedirectPage from './pages/RedirectPages/AuthRedirectPage';
import NewEstablishmentRedirect from './pages/RedirectPages/NewEstablishmentRedirect';
import WriteReviewPage from './pages/WriteReviewPage';
import { setCredentials } from "./store/authSlice";
import { GetUserWithIdApiArg, useGetUserSavedJobListingsQuery, useGetUserWithIdQuery } from './store/kcApi';
import { setSavedJobs } from './store/savedJobsSlice';
import { RootState } from './store/store';
import { usePostHog } from 'posthog-js/react'
import ReviewsPage from "./pages/ReviewsPageTemp/ReviewsEmbedded";
import JobsPage from "./pages/JobsPageTemp/JobsEmbedded";

const ProtectedRoute = ({ component: Component }: { component: React.FC<{}> }) => {
  const { isLoading, isAuthenticated } = useAuth0();

  // Redirect to "/home" if not authenticated
  return (isAuthenticated || isLoading) ? <Component /> : <Navigate to="/home" replace />;
};

export default function App() {

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID as string);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const { isLoading, isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useDispatch();
  const posthog = usePostHog();

  const [userSearch, setUserSearch] = React.useState<string>("");

  const currentUser = useSelector((state: RootState) => state.auth);
  const {
    data: savedJobsResponse,
    error: savedJobsError,
    isLoading: loadingSavedJobs
  } = useGetUserSavedJobListingsQuery({ userId: currentUser.user_id, limit: 1000, page: 1 });
  useEffect(() => {
    dispatch(setSavedJobs({ jobList: (savedJobsResponse?.data ? savedJobsResponse.data : []), savedJobsError, loadingSavedJobs }));
  }, [savedJobsResponse, savedJobsError, loadingSavedJobs, dispatch]);

  const {
    data: kcUser,
    isLoading: userLoading,
  } = useGetUserWithIdQuery(
    { userId: userSearch } as GetUserWithIdApiArg,

  );

  useEffect(() => {
    const initAuth0 = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        dispatch(setCredentials({ token: accessToken, user: user?.sub, appstate: null, user_id: kcUser?.id || null }));
      }
    };
    initAuth0();

    if (isAuthenticated && !isLoading && user !== undefined) {
      setUserSearch(user?.sub || "");
    }
  }, [isAuthenticated, isLoading, user, kcUser, userLoading, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (kcUser !== undefined && user !== undefined) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(kcUser.id, {
        sub: user.sub,
      });
    }
}, [posthog, user, kcUser])
  
  const RedirectToAuth0 = () => {
    useEffect(() => {
      window.location.href = process.env.REACT_APP_LOGIN_URL + "?response_type=code&client_id=" + process.env.REACT_APP_AUTH0_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_AUTH0_CALLBACK_URL;
    }, []);

    return null;
  }

  const LogoutComponent = () => {
    useEffect(() => {
      logout({ logoutParams: { returnTo: "/" } });
    }, []);

    return null;
  }

  const router = createBrowserRouter([
    {
      Component: Layout,
      children: [
        { path: "/", Component: HomePage },
        { path: "/login", Component: RedirectToAuth0 },
        { path: "/logout", Component: LogoutComponent },
        { path: "/redirect", Component: AuthRedirectPage },
        { path: "/home", Component: HomePage },
        {
          path: "/explore",
          element: <ProtectedRoute component={ExplorePage} />,
        },
        {
          path: "/profile",
          element: <ProtectedRoute component={ProfilePage} />,
        },
        { path: "/establishments", Component: ExplorePage },
        { path: "/about", Component: AboutUsPage },
        { path: "/contact", Component: ContactUsPage },
        { path: "/write-review", Component: WriteReviewPage },
        { path: "/establishments/new", Component: NewEstablishmentRedirect },
        {
          path: "/establishments/:establishmentId/new-review",
          Component: NewReviewLandingPage,
        },
        {
          path: "/establishments/:establishmentId/new-review/:reviewId",
          Component: NewReviewPage,
        },
        {
          path: "/establishments/:establishmentId/new-review/:reviewId/submitted",
          Component: NewReviewSubmittedPage,
        },
        {
          path: "/establishments/:establishmentId",
          Component: EstablishmentPage,
        },
        { path: "/privacy", Component: PrivacyPolicyPage },
        { path: "/terms", Component: TermsOfUsePage },
        { path: "/welcome", element: <Navigate to="/" replace /> },
        { path: "/reviews", Component: ReviewsPage },
        { path: "/jobs", Component: JobsPage },
        {
          path: "*",
          Component: NotFound
        }
      ]
    }
  ]);


  return <RouterProvider router={router} />;
}

